import React from "react";
import PropTypes from "prop-types";
import {File} from './file';
import {Folder} from './folder'
import {ElnoksegSubPage} from './elnoksegSubPage'
import { devURL} from '../../../config/pages';

import * as styles from './elnoksegFiles.module.scss';



export class ElnoksegFiles extends React.Component {
	constructor(props) {
        super(props);

        this.state = { 
            files: [],
      
        };   

        this.getFileURL = this.getFileURL.bind(this);

    }   

    getFileURL() {
        fetch(devURL + '/php/get_file_URLs.php',
        {
            method: 'POST',
            headers: 
            {
                
            },
            body: JSON.stringify(
            {
                token: this.props.token,
 
            })
 
        })
        .then((response) => response.json())
        .then((responseJson) =>
        {
            this.setState({files: responseJson});    
        }).catch((error) =>
        {
            console.error(error);
        });

    }

    componentDidMount() {
        this.getFileURL();
    }


    render() {
        const alap = this.state.files.filter((f, i) => f.type === "Alapszabály");
        const nyilatkozatok = this.state.files.filter((f, i) => f.type === "Belépési nyilatkozatok");
        const jgyzk = this.state.files.filter((f, i) => f.type === "Elnökségi jegyzőkönyvek");
        const eler = this.state.files.filter((f, i) => f.type === "Elérhetőségek");
        const palyazatok = this.state.files.filter((f, i) => f.type === "Pályázatok");
        
        return (
            <ElnoksegSubPage displayGoBack={true}>
            <div className={styles.container}>
                

                <Folder name="Alapszabály">
                    {alap.map((f, i) => <File key={i} name={f.name} url={f.url}/>)}
                </Folder>
                <Folder name="Belépési nyilatkozatok">
                    {nyilatkozatok.map((f, i) => <File key={i} name={f.name} url={f.url}/>)}
                </Folder>
                <Folder name="Pályázati anyagok">
                    {palyazatok.map((f, i) => <File key={i} name={f.name} url={f.url}/>)}
                </Folder>
                <Folder name="Elnökségi jegyzőkönyvek">
                    {jgyzk.map((f, i) => <File key={i} name={f.name} url={f.url} date={f.date}/>)}
                </Folder>
                <Folder name="Elérhetőségek">
                    {eler.map((f, i) => <File key={i} name={f.name} url={f.url}/>)}
                </Folder>



			</div>

            </ElnoksegSubPage>
        );
    }
}

ElnoksegFiles.propTypes = {
   
    token: PropTypes.string.isRequired,


    
};