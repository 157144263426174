import React from "react";
import PropTypes from "prop-types";
import {FolderOpen} from '../../img/folderOpen';
import {FolderClosed} from '../../img/folderClosed';


import * as styles from './folder.module.scss';



export class Folder extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            open: false,
      
        };   

        this.toggleFolder = this.toggleFolder.bind(this);

    }   

    toggleFolder() {
        this.setState({open: !this.state.open});
    }


    render() {

        return (
            <div className={styles.container}>
               <div onClick={this.toggleFolder} className={styles.folderTitle}>
                   {this.state.open ? 
                    <FolderOpen className={styles.folder} /> : 
                   <FolderClosed className={styles.folder} />}
                   <p className={styles.name}>{this.props.name}</p>
               </div>

               {this.state.open && <div className={styles.filesContainer}>

                    {this.props.children}
               </div>}

            </div>

        );
    }
}

Folder.propTypes = {
   
    name: PropTypes.string.isRequired,
    
};