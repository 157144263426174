import React from "react";
import PropTypes from "prop-types";


import * as styles from './file.module.scss';



export class File extends React.Component {
    


    render() {

        return (
            <div className={styles.container}>
                

               <a href={this.props.url} target="_blank" rel="noopener noreferrer" >
                    <p className={styles.name}>{this.props.name}</p>
                    <p className={styles.date}>{this.props.date}</p>

               </a>


            </div>

        );
    }
}

File.propTypes = {
   
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string,


    
};