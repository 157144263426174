import React from "react";
import {goToPreviousPage} from '../../img/goToPreviousPage';
import {ContentPage} from "../contentPage";
import {Link} from '../../../common/link';
import {Pages} from '../../../config/pages';
import PropTypes from "prop-types";



import * as styles from './elnoksegSubPage.module.scss';



export class ElnoksegSubPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayGoBack: true
        };
    }

    componentDidMount() {
        const displayGoBack = this.props.displayGoBack; 
        this.setState({displayGoBack});
    }

    componentDidUpdate(prevProps) {
        if (this.props.displayGoBack !== prevProps.displayGoBack) {
            const displayGoBack = this.props.displayGoBack; 
            this.setState({displayGoBack});
        }
    }

    render() {
        
        return (
            <ContentPage activePage={-1}>
                <div className={`${styles.container} ${!this.state.displayGoBack && styles.noGoBack}`}>
                    {this.state.displayGoBack && <Link className={styles.arrow} location={Pages.elnokseg.home}>
                        {goToPreviousPage}
                    </Link>}
                    {this.props.children}
                </div>
            </ContentPage>
        );
    }
}

ElnoksegSubPage.propTypes = {
    displayGoBack: PropTypes.bool.isRequired,
 
};